<template>
  <div class="image-wrapper w-full shrink-0">
    <img
      :src="imageSrc"
      alt="Image"
      class="w-full aspect-[319/133] bg-cover object-cover bg-center bg-no-repeat"
    >
  </div>
</template>


<script>
export default {
  props: {
    imageSrc: String,
  }
}
</script>