<template>
  <section class="popular-categories">
    <TitleItem text="Популярные категории товаров"></TitleItem>
    <div v-if="!isCategoriesLoading" class="popular-categories__container rounded-[20px] max-[1000px]:rounded-[10px] max-[800px]:rounded-[5px] py-[1.2%] px-[0.5%] max-w-full">
      <div v-if="!mobile" class="popular-categories__sample w-auto flex flex-row justify-center gap-[2%]">
        <div
          class="popular-categories__pair w-[23%] flex flex-col justify-start gap-[35px] max-[1440px]:gap-[20px]"
          v-for="pair in categoriesPairs" :key="pair"
        >
          <CategoryCard
            v-for="category in pair"
            :key="category"
            :image="category.image"
            :name="category.name"
            :id="category.id"
            :home="true"
          ></CategoryCard>
        </div>
      </div>
      <div v-else class="popular-categories__sample w-auto flex flex-row justify-center gap-[2.5%]">
        <div
          class="popular-categories__pair w-[31%] flex flex-col justify-start gap-[20px] max-[800px]:gap-[10px] max-[500px]:gap-[5px]"
          v-for="pair in categoriesPairs" :key="pair"
        >
          <CategoryCard
            v-for="category in pair"
            :key="category"
            :image="category.image"
            :name="category.name"
            :id="category.id"
            :home="true"
          ></CategoryCard>
        </div>
      </div>
    </div>
    <LoadingMark v-else></LoadingMark>
  </section>
</template>


<script>
import axiosInstance from '@/const.js'
import CategoryCard from '@/components/CategoryCard'
import TitleItem from '@/components/TitleItem'
import LoadingMark from '@/components/UI/LoadingMark'

export default {
  components: {
    CategoryCard,
    TitleItem,
    LoadingMark,
  },
  data() {
    return {
      categoriesPairs: [],
      isCategoriesLoading: false,
      mobile: false,
    }
  },
  methods: {
    async getCategories() {
      try {
        this.isCategoriesLoading = true;
        const response = await axiosInstance.get('catalog/categories/popular/');
        if (response.status !== 200) {
          throw new Error(`Status ${response.status}`);
        }
        let currentPair = [];
        let categories = response.data;
        if (window.innerWidth <= 900 && categories.length > 6) {
          categories = categories.slice(0, 6);
          this.mobile = true;
        }
        for (let category of categories) {
          if (currentPair.length < 2) {
            currentPair.push(category);
          } else {
            this.categoriesPairs.push(currentPair);
            currentPair = [category];
          }
        }
        this.categoriesPairs.push(currentPair);
      } catch (e) {
        alert(e);
      } finally {
        this.isCategoriesLoading = false;
      }
    }
  },
  mounted() {
    this.getCategories();
  }
}
</script>


<style lang="scss">
@import '@/assets/variables.scss';
.popular-categories__container {
  background-color: $categories-sample-background;
}
</style>