<template>
  <section
    v-if="products !== undefined && products.length > 0"
    class="rounded-[13px] mt-8 flex flex-col justify-center overflow-y-hidden"
  >
    <TitleItem class="ml-[15px]" :text="title"></TitleItem>
    <div
      v-if="!isProductsLoading"
      class="products-sample p-[14px] max-[800px]:p-[10px] max-[500px]:p-[5px] cursor-grab overflow-x-scroll overflow-y-hidden max-w-full"
      @wheel.prevent="handleWheel"
      @mousedown.prevent="startScroll"
      @mouseup="stopScroll"
      @mouseleave="stopScroll"
      @mousemove.prevent="handleScroll"
      :id="`product-sample-${path}`"
    >
      <div class="products flex flex-row gap-[20px] max-[800px]:gap-[10px] max-[500px]:gap-[5px]">
        <ProductCard
          v-for="product in products"
          :key="product"
          :product="product"
          @mouseup.stop.prevent
        ></ProductCard>
      </div>
    </div>
    <LoadingMark v-else></LoadingMark>
  </section>
</template>


<script>
import axiosInstance from '@/const.js'
import ProductCard from '@/components/ProductCard'
import TitleItem from '@/components/TitleItem'
import LoadingMark from '@/components/UI/LoadingMark'

export default {
  components: {
    ProductCard,
    TitleItem,
    LoadingMark,
  },
  props: {
    title: String,
    path: {
      type: String,
      default: '',
    },
    inputProducts: null,
  },
  data() {
    return {
      products: [],
      isProductsLoading: false,
      startX: null,
      scrollLeft: null,
      element: null,
      isScrolling: false,
    }
  },
  methods: {
    async getProducts() {
      try {
        this.isProductsLoading = true;
        const response = await axiosInstance.get(
          `catalog/products/${this.path}/`
        )
        if (response.status !== 200) {
          throw new Error(`Status ${response.status}`);
        }
        this.products = response.data;
      } catch (e) {
        alert(e);
      } finally {
        this.isProductsLoading = false;
      }
    },
    handleWheel(event) {
      this.element.scrollLeft += event.deltaY;
    },
    startScroll(event) {
      this.isScrolling = true;
      this.startX = event.clientX;
      this.scrollLeft = this.element.scrollLeft;
    },
    stopScroll() {
      this.isScrolling = false;
    },
    handleScroll(event) {
      if (!this.isScrolling || !this.element) return;
      const delta = event.clientX - this.startX;
      this.element.scrollLeft = this.scrollLeft - delta;
    },
  },
  mounted() {
    if (this.inputProducts) {
      this.products = this.inputProducts;
    } else {
      this.getProducts();
    }
  },
  updated() {
    this.$nextTick(function () {
      this.element = document.querySelector(`#product-sample-${this.path}`);
    })
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';

section {
  background-color: $sample-background;
}

.products-sample {
  scrollbar-width: none;
}

.products-sample::-webkit-scrollbar {
  display: none;
}
</style>