<template>
  <section class="header-info flex justify-center items-center py-[5px]">
    <div class="flex flex-row justify-between items-center max-w-[1440px] h-full">
      <router-link to="/">
        <LogoItem :isHeader="true"></LogoItem>
      </router-link>
      <div class="header-items flex flex-row justify-between items-center">
        <div class="header-item">
          <address class="address-map">
            <a
              class="yandex-map lg:hover:underline"
              href="https://yandex.ru/maps/org/tsvetochnaya_palitra/80568735750/?ll=43.934146%2C56.193252&z=14"
              target="_blank"
            ><strong>Адрес:</strong> Полевая улица, 52, село Ближнее Борисово, Нижегородская область</a>
          </address>
        </div>
        <div class="header-item company-info-item">
          <p class="company-info">
            <em><strong>Режим работы:</strong> ежедневно c 10:00 до 18:00</em>
            <br>
            <em><strong>Оплата:</strong> наличными, переводом, картой или по QR-коду</em>
          </p>
        </div>
        <div class="header-item">
          <address class="font-medium text-[22px] max-[1200px]:text-[19px] max-[1000px]:text-[17px] max-[800px]:text-[14px] max-[600px]:text-[11px] max-[500px]:text-[9px] max-[420px]:text-[6.5px]">
            <p class="leading-[150%] whitespace-nowrap">
              <em><strong>Контакты:</strong><br><a href="tel:+79202538078">8 920 253 80 78</a></em>
            </p>
          </address>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import LogoItem from '@/components/LogoItem';

export default {
  components: {LogoItem},
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';

.header-info {
  background-color: #FFE0D1;
  max-width: 100%;
  height: 154px;

  @media (max-width: 800px) {
    height: 100px;
  }

  @media (max-width: 600px) {
    height: 60px;
  }
}

.header-items {
  margin-left: 25px;
  margin-right: 50px;

  @media (max-width: 1200px) {
    margin-left: 10px;
    margin-right: 20px;
    gap: 10px;
  }

  @media (max-width: 600px) {
    margin-right: 10px;
  }
}

.header-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: start;
  max-width: 40%;
}

.company-info-item {
  margin-right: 50px;

  @media (max-width: 1300px) {
    margin-right: 20px;
  }
  @media (max-width: 1200px) {
    margin-right: 0;
  }
}

.company-info,
.address-map {
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 100px) {
    font-size: 14px;
  }

  @media (max-width: 800px) {
    font-size: 12px;
  }

  @media (max-width: 600px) {
    font-size: 9px;
  }

  @media (max-width: 540px) {
    font-size: 7px;
  }

  @media (max-width: 480px) {
    font-size: 6px;
  }
}

.yandex-map {
  text-decoration: none;
  color: black;
}

p {
  color: black;
}
</style>