<template>
  <div class="logo h-full">
    <img
      :class="{ 'w-[144px] max-[800px]:w-[90px] max-[600px]:w-[50px]': isHeader, 'w-[100px] max-[600px]:w-[200px]': !isHeader }"
      src="@/assets/images/logo.webp"
      alt="Logo"
    >
  </div>
</template>

<script>
export default {
  props: {
    isHeader: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.logo {
  margin-left: 50px;
  margin-right: 25px;

  @media (max-width: 1200px) {
    margin-left: 25px;
    margin-right: 15px;
  }

  @media (max-width: 700px) {
    margin-left: 10px;
    margin-right: 5px;
  }

  img {
    aspect-ratio: 1 / 1;
    max-width: none;
  }
}
</style>