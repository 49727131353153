<template>
  <section class="catalog flex flex-col justify-center">
    <TitleItem text="Категории товаров"></TitleItem>
    <div v-if="!isCategoriesLoading" class="flex flex-col justify-center items-center gap-[10px]">
      <div v-for="row in categoriesRows" :key="row" class="row w-full flex flex-row justify-start gap-[1.25%]">
        <CategoryCard
          v-for="category in row"
          :key="category"
          :image="category.image"
          :name="category.name"
          :id="category.id"
        ></CategoryCard>
      </div>
    </div>
    <LoadingMark v-else></LoadingMark>
  </section>
</template>

<script>
import axiosInstance from '@/const.js'
import CategoryCard from '@/components/CategoryCard'
import TitleItem from '@/components/TitleItem'
import LoadingMark from '@/components/UI/LoadingMark'

export default {
  components: {
    CategoryCard,
    TitleItem,
    LoadingMark,
  },
  data() {
    return {
      categoriesRows: [],
      isCategoriesLoading: false,
      rowLength: 5,
    }
  },
  methods: {
    async getCategories() {
      try {
        this.isCategoriesLoading = true;
        const response = await axiosInstance.get('catalog/categories/');
        if (response.status !== 200) {
          throw new Error(`Status ${response.status}`);
        }
        let currentRow = [];
        if (window.innerWidth <= 900) {
          this.rowLength = 3;
        }
        for (let category of response.data) {
          if (currentRow.length < this.rowLength) {
            currentRow.push(category);
          } else {
            this.categoriesRows.push(currentRow);
            currentRow = [category];
          }
        }
        this.categoriesRows.push(currentRow);
      } catch (e) {
        alert(e);
      } finally {
        this.isCategoriesLoading = false;
      }
    },
  },
  mounted() {
    this.getCategories();
  }
}
</script>