<template>
  <section v-if="images" class="carousel w-full flex flex-row justify-between items-center">
    <button v-if="!$store.state.isMobile" @click="moveToLeft">
      <LeftArrow></LeftArrow>
    </button>
    <div
      class="carousel__container overflow-hidden rounded-[30px] max-[800px]:rounded-[10px]"
      @touchstart="touchStartHandler"
      @touchend="touchEndHandler"
      @touchmove="touchMoveHandler"
      @mousedown="mouseDownHandler"
      @mouseup="mouseUpHandler"
    >
      <div
        class="images-row-wrapper left-[-1220px] flex flex-row"
        :style="carouselStyle"
        @transitionend="onTransitionEnd"
      >
        <CarouselImage
          v-for="(imgIndex, index) in showedImages"
          :key="index"
          :imageSrc="images[imgIndex].image"
        ></CarouselImage>
      </div>
    </div>
    <button v-if="!$store.state.isMobile" @click="moveToRight">
      <RightArrow></RightArrow>
    </button>
  </section>
</template>


<script>
import LeftArrow from '@/components/UI/LeftArrow'
import RightArrow from '@/components/UI/RightArrow'
import CarouselImage from '@/components/HomePage/CarouselImage';
import axiosInstance from '@/const'

export default {
  components: {
    LeftArrow,
    RightArrow,
    CarouselImage,
  },
  data() {
    return {
      isImagesLoading: false,
      images: [],
      imagesIndexes: [],
      showedImages: [],
      url: 'common/main-slider-photo/',
      showedImageKey: 0,
      offsetWidth: 0,
      timerId: null,
      timeout: 3000,
      sliding: false,
      screenMiddle: null,
      startX: null,
      currentX: null,
    }
  },
  computed: {
    carouselStyle() {
      return {
        transform: `translate(-${this.showedImageKey * this.offsetWidth}px)`,
        transition: this.sliding ? 'transform .5s ease' : 'none',
      }
    },
  },
  methods: {
    async getImages() {
      try {
        this.isImagesLoading = true;
        const response = await axiosInstance.get(this.url);
        if (response.status !== 200) {
          throw new Error();
        }
        this.setImages(response.data);
      } catch (e) {
        if (!this.images) {
          this.images.push({
            image: '@/assets/images/banner2.webp',
            is_main: true,
          })
        }
      } finally {
        this.isImagesLoading = false;
      }
    },
    setImages(data) {
      for (let i = 0; i < data.length; i++) {
        this.imagesIndexes.push(i);
        let image = data[i];
        if (image.is_main) {
          this.images.splice(0, 0, image);
        } else {
          this.images.push(image);
        }
      }
      this.showedImages = this.showedImages
        .concat(this.imagesIndexes)
        .concat(this.imagesIndexes)
    },
    setImgWidth() {
      const carouselContainer = document.querySelector('.carousel__container');
      this.offsetWidth = carouselContainer.offsetWidth;
    },
    moveToRight() {
      this.setNewTimeout();
      this.sliding = true;
      if (this.showedImageKey < this.images.length * 2 - 1) this.showedImageKey++;
    },
    moveToLeft() {
      this.setNewTimeout();
      this.sliding = true;
      if (this.showedImageKey > 0) this.showedImageKey--;
    },
    onTransitionEnd() {
      if (this.showedImageKey === 0) {
        this.sliding = false;
        this.showedImageKey = this.images.length;
      } else if (this.showedImageKey === this.images.length * 2 - 1) {
        this.sliding = false;
        this.showedImageKey = this.images.length - 1;
      }
    },
    autoPlayNextSlide() {
      this.moveToRight();
      this.setNewTimeout();
    },
    setNewTimeout() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(
        this.autoPlayNextSlide.bind(this),
        this.timeout,
      );
    },
    setScreenMiddle() {
      this.screenMiddle = window.screen.width / 2;
    },
    mouseDownHandler() {
      clearTimeout(this.timerId);
    },
    mouseUpHandler() {
      this.setNewTimeout();
    },
    touchStartHandler(event) {
      clearTimeout(this.timerId);
      this.startX = event.touches[0].clientX;
    },
    touchEndHandler() {
      if (this.currentX === null) {
        this.setNewTimeout();
      } else if (this.startX < this.screenMiddle && this.currentX > this.screenMiddle) {
        this.moveToLeft();
      } else if (this.startX > this.screenMiddle && this.currentX < this.screenMiddle) {
        this.moveToRight();
      }
      this.startX = null;
      this.currentX = null;
    },
    touchMoveHandler(event) {
      this.currentX = event.touches[0].clientX;
    }
  },
  mounted() {
    this.getImages();
    this.setImgWidth();
    this.setNewTimeout();
    this.setScreenMiddle();
  }
}
</script>


<style lang="scss" scoped>
.carousel__container {
  max-width: 1220px;
  width: 92%;
  position: relative;

  @media (max-width: 900px) {
    width: 100%;
  }
}
</style>