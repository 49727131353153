<template>
  <router-link :to="{ name: 'catalog-by-category', params: { id: this.id, name: this.name } }"
    :class="{ 'category-card': !home, 'category-card-home': home, 'flex flex-col justify-end items-start rounded-[10px] max-[800px]:rounded-[5px] no-underline bg-cover bg-center bg-no-repeat': true }"
    :style="{ backgroundImage: `url(${image})` }"
  >
    <div class="category-card__name box-content flex flex-row items-center justify-between">
      <h3 :class="{ 'font-bold text-left lg:hover:underline': true, 'name-text': !home, 'name-text-home': home }">{{ name }}</h3>
    </div>
  </router-link>
</template>


<script>
export default {
  props: {
    image: String,
    name: String,
    id: Number,
    home: {
      type: Boolean,
      default: false,
    },
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';

.category-card {
  width: 19%;

  @media (max-width: 900px) {
    width: 32%;  
  }
}

.category-card-home {
  width: 100%;
}

.category-card,
.category-card-home {
  aspect-ratio: 1 / 1;
}

.name-text {
  font-size: 1vw;
  
  @media (min-width: 1440px) {
    font-size: 14px;
  }

  @media(max-width: 900px) {
    font-size: 1.4vw;
  }
}

.name-text-home {
  font-size: 1.1vw;

  @media (min-width: 1440px) {
    font-size: 16px;
  }

  @media(max-width: 900px) {
    font-size: 1.5vw;
  }
}

.category-card__name {
  background-color: $dark-yellow;
  color: $text-dark;
  width: 85%;
  height: 25%;
  border-radius: 0 0 0 10px;
  padding-left: 3%;
  position: relative;

  @media (max-width: 800px) {
    border-radius: 0 0 0 5px;
  }
}

.category-card__name::after {
  content: '';
  height: 100%;
  width: 10%;
  transform: skewX(-15deg);
  background-color: $dark-yellow;
  position: absolute;
  right: -5%;
}
</style>